<script setup>
const props = defineProps({
  group: String,
  equipment: Array
})
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-3 border-b border-b-gray-400 py-4 mx-8 last:border-b-0 gap-4">
    <div class="">{{ group }}</div>
    <div class="md:col-span-2 font-extralight space-y-0.5">
      <template v-for="(eq) in props.equipment">
        <div>{{ eq }}</div>
      </template>
    </div>
  </div>
</template>
